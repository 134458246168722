import React from 'react';
import { Link } from 'react-router-dom';
import useJalaliDate from '../../../Logics/useJalaliDate';

const ReferenceBox = ({ data }) => {
    const replaceZWNJWithUnicode = (text) => {
        return text.replace(/\u200C/g, '\u200C');
    };

    return (
        <>
            <a href={(data?.link) ? data?.link : data?.file} target='_blank' className='flex justify-center items-start bg-[#F7F7F7] p-[0.5rem] rounded-[0.25rem] btn-click transition-[0.3s]'>
                <div className='w-max ml-[1rem] min-w-max'>
                    <img className='w-[4.8rem] h-[7rem] rounded-[0.5rem]' src={data?.image} />
                </div>

                <div className='w-full overflow-hidden text-ellipsis'>
                    <div className='text-nowrap w-full text-[14px] text-[#212529]'>
                        <p className='overflow-hidden text-ellipsis'>{data?.title}</p>
                    </div>

                    <div className='w-full text-[12px] text-[#89939C] text-nowrap text-ellipsis overflow-hidden'>
                        <p className='!leading-5 overflow-hidden text-ellipsis'>{data?.sub_title}</p>
                    </div>

                    <div className='w-full text-[10px] text-[#1C89EB] font-bold text-nowrap text-ellipsis overflow-hidden'>
                        <p className='overflow-hidden text-ellipsis'>{data?.author}</p>
                    </div>

                    <div className='w-full text-[12px] text-[#89939C] text-nowrap text-ellipsis overflow-hidden'>
                        <p className='!leading-5 overflow-hidden text-ellipsis'>{data?.publisher}</p>
                    </div>
                </div>

                <div className='flex justify-center items-center h-full mr-[1rem]'>
                    {(data?.link) ? (
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4.50195C4.73478 4.50195 4.48043 4.60731 4.29289 4.79485C4.10536 4.98238 4 5.23674 4 5.50195V19.502C4 19.7672 4.10536 20.0215 4.29289 20.2091C4.48043 20.3966 4.73478 20.502 5 20.502H19C19.2652 20.502 19.5196 20.3966 19.7071 20.2091C19.8946 20.0215 20 19.7672 20 19.502V14.239C20 13.9737 20.1054 13.7194 20.2929 13.5318C20.4804 13.3443 20.7348 13.239 21 13.239C21.2652 13.239 21.5196 13.3443 21.7071 13.5318C21.8946 13.7194 22 13.9737 22 14.239V19.502C22 20.2976 21.6839 21.0607 21.1213 21.6233C20.5587 22.1859 19.7956 22.502 19 22.502H5C4.20435 22.502 3.44129 22.1859 2.87868 21.6233C2.31607 21.0607 2 20.2976 2 19.502V5.50195C2 4.7063 2.31607 3.94324 2.87868 3.38063C3.44129 2.81802 4.20435 2.50195 5 2.50195H10.017C10.2822 2.50195 10.5366 2.60731 10.7241 2.79485C10.9116 2.98238 11.017 3.23674 11.017 3.50195C11.017 3.76717 10.9116 4.02152 10.7241 4.20906C10.5366 4.3966 10.2822 4.50195 10.017 4.50195H5Z" fill="#0C0C0C"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4111 3.07187C21.5911 3.25239 21.6922 3.49693 21.6922 3.75187C21.6922 4.00681 21.5911 4.25135 21.4111 4.43187L12.6391 13.2179C12.55 13.3071 12.4441 13.3778 12.3276 13.4261C12.2111 13.4744 12.0862 13.4992 11.9601 13.4992C11.834 13.4992 11.7091 13.4744 11.5926 13.4261C11.4761 13.3778 11.3703 13.3071 11.2811 13.2179C11.1011 13.0373 11 12.7928 11 12.5379C11 12.2829 11.1011 12.0384 11.2811 11.8579L20.0541 3.07187C20.2341 2.89203 20.4782 2.79102 20.7326 2.79102C20.9871 2.79102 21.2311 2.89203 21.4111 3.07187Z" fill="#0C0C0C"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.04 2.50195C21.57 2.50195 22 2.93195 22 3.46395V8.50195C22 9.03295 21.53 9.50195 21 9.50195C20.47 9.50195 20 9.03295 20 8.50195V4.50195H16C15.47 4.50195 15 4.03295 15 3.50195C15 2.97095 15.43 2.50195 15.96 2.50195H21.04Z" fill="#0C0C0C"/>
                        </svg>
                    ) : (
                        <svg width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.55 11.1489V0.498047H9.45V11.149L5.99241 7.49015L4.50749 9.0615L9.75749 14.6171C10.1675 15.051 10.8324 15.051 11.2424 14.6171L16.4924 9.0615L15.0075 7.49015L11.55 11.1489Z" fill="#089584"/>
                        <path d="M0 16.0536V17.1647C0 19.0057 1.4103 20.498 3.15 20.498H17.85C19.5897 20.498 21 19.0057 21 17.1647V16.0536H18.9V17.1647C18.9 17.7784 18.4299 18.2758 17.85 18.2758H10.5H3.15C2.5701 18.2758 2.1 17.7784 2.1 17.1647V16.0536H0Z" fill="#089584"/>
                        <path d="M11.55 11.1489V0.498047H9.45V11.149L5.99241 7.49015L4.50749 9.0615L9.75749 14.6171C10.1675 15.051 10.8324 15.051 11.2424 14.6171L16.4924 9.0615L15.0075 7.49015L11.55 11.1489Z"/>
                        <path d="M0 16.0536V17.1647C0 19.0057 1.4103 20.498 3.15 20.498H17.85C19.5897 20.498 21 19.0057 21 17.1647V16.0536H18.9V17.1647C18.9 17.7784 18.4299 18.2758 17.85 18.2758H10.5H3.15C2.5701 18.2758 2.1 17.7784 2.1 17.1647V16.0536H0Z"/>
                        </svg>
                    )}
                </div>
            </a>
        </>
    );
};

export default ReferenceBox;