import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router ,Route,Routes, createBrowserRouter, createRoutesFromElements,RouterProvider } from "react-router-dom";
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import './index.css';
import './Assets/Css/Style.css';
import Home from './Pages/Home/Home';
import HomeLeyout from './Leyouts/HomeLeyout';
import store from './Store/store';
import Premium from './Pages/Premium/Premium';
import Chest from './Pages/Chest/Chest';
import Article from './Pages/Chest/Article';
import NoPage from './Pages/NoPage/NoPage';
import Policy from './Pages/Policy/Policy';
import Privacy from './Pages/Privacy/Privacy';
import ContactUs from './Pages/ContactUs/ContactUs';
import AboutUs from './Pages/AboutUs/AboutUs';
import Faq from './Pages/Faq/Faq';
import Shop from './Pages/Shop/Shop';
import Auth from './Pages/Auth/Auth';
import ShopLeyout from './Leyouts/ShopLeyout';
import AuthLeyout from './Leyouts/AuthLeyout';
import ProductLeyout from './Leyouts/ProductLeyout';
import Product from './Pages/Product/Product';
import Cart from './Pages/Cart/Cart';
import Payment from './Pages/Payment/Payment';
import PaymentLeyout from './Leyouts/PaymentLeyout';
import Search from './Pages/Search/Search';
import Logout from './Pages/Auth/Logout';
import Tools from './Pages/Tools/Tools';
import MyOrders from './Pages/MyOrders/MyOrders';
import DashboardLeyout from './Leyouts/DashboardLeyout';
import MyWishlist from './Pages/MyWishlist/MyWishlist';
import Address from './Pages/Address/Address';
import Dashboard from './Pages/Dashboard/Dashboard';
import ProtectedRoute from './Logics/ProtectedRoute';
import useAuthRedirect from './Logics/useAuthRedirect';
import Piece from './Pages/Chest/Piece';
import Theory from './Pages/Chest/Theory';
import Articles from './Pages/Chest/Articles';
import Pieces from './Pages/Chest/Pieces';
import References from './Pages/Chest/References';
import Theories from './Pages/Chest/Theories';
import ChestLeyout from './Leyouts/ChestLeyout';

const queryClient = new QueryClient();
const routers = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="" element={<HomeLeyout />}>
        <Route index={true} element={<Home />} />
        <Route path={'/invoice'} element={<Premium />} />
        <Route path={'/piece/:id'} element={<Piece />} />
        <Route path={'/articles/:id'} element={<Article />} />
        <Route path={'/policy'} element={<Policy />} />
        <Route path={'/privacy'} element={<Privacy />} />
        <Route path={'/contact-us'} element={<ContactUs />} />
        <Route path={'/about-us'} element={<AboutUs />} />
        <Route path={'/faq'} element={<Faq />} />
        <Route path={'/tools'} element={<Tools />} />
        <Route path={'*'}  element={<NoPage/>}/>
      </Route>

      <Route path="" element={<ChestLeyout />}>
        <Route path={'/chest'} element={<Chest />} />
        <Route path={'/articles/'} element={<Articles />} />
        <Route path={'/pieces/'} element={<Pieces />} />
        <Route path={'/references/'} element={<References />} />
        <Route path={'/theories/'} element={<Theories />} />
        <Route path={'/theory/'} element={<Theory />} />
      </Route>

      <Route path="" element={<PaymentLeyout />}>
        <Route path={'/payment'} element={
          <ProtectedRoute>
            <Payment />
          </ProtectedRoute>
        } />
      </Route>

      <Route path="" element={<ShopLeyout />}>
        <Route path={'/shop'} element={<Shop />} />
        <Route path={'/search'} element={<Search />} />
        <Route path={'/cart'} element={<Cart />} />
        <Route path={'/my-orders'} element={
          <ProtectedRoute>
            <MyOrders />
          </ProtectedRoute>
        } />
      </Route>

      <Route path="" element={<DashboardLeyout />}>
        <Route path={'/my-wishlist'} element={
          <ProtectedRoute>
            <MyWishlist />
          </ProtectedRoute>
        } />
        <Route path={'/address'} element={
          <ProtectedRoute>
            <Address />
          </ProtectedRoute>
        } />
        <Route path={'/dashboard'} element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        } />
      </Route>

      <Route path="" element={<AuthLeyout />}>
        <Route path={'/auth'} element={<Auth />} />
      </Route>
      
      <Route path={'/logout'} element={<Logout />} />

      <Route path="" element={<ProductLeyout />}>
        <Route path={'/product/:id'} element={<Product />} />
      </Route>
    </>
  )
)

const root = ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <HelmetProvider>
          <RouterProvider router={routers} />
        </HelmetProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);