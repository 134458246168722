import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useJalaliDate from '../../../Logics/useJalaliDate';
import { useSelector } from 'react-redux';

const PieceBox = ({ data }) => {
    const { profile, status } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const replaceZWNJWithUnicode = (text) => {
        return text.replace(/\u200C/g, '\u200C');
    };

    const click = (premium,link) => {
        if(premium == 1)
        {
            if(!profile && status == 'idle')
            {
                document.getElementById('show-permum-modal')?.click();
                localStorage.setItem('openLinkAfterLoginPieces', link);
            }else{
                const now = new Date();
                const expiresAt = new Date(profile?.data?.subscription_expires_at);
                const isExpired = expiresAt < now;

                if(!isExpired)
                {
                    navigate(link);
                }else{
                    document.getElementById('show-permum-modal')?.click();
                    localStorage.setItem('openLinkAfterLoginPieces', link);
                }
            }
        }else{
            navigate(link);
        }
    }
    
    return (
        <>
            <button onClick={(e) => click(data?.premium,`/piece/${data?.slug}`)} className='flex justify-center items-start bg-[#F7F7F7] p-[0.5rem] rounded-[0.5rem] btn-click transition-[0.3s]'>
                <div>
                    <img className='w-[4.5rem] h-[4.5rem] min-w-[4.5rem] rounded-[0.5rem] object-cover' src={data?.media?.original} />
                </div>

                <div className='w-full overflow-hidden text-ellipsis mr-[1rem] ml-[1.5rem] text-right'>
                    <div className='w-full text-[14px] text-[#212529]'>
                        <p className='text-nowrap overflow-hidden text-ellipsis'>{data?.title}</p>
                    </div>

                    <div className='w-fulltext-ellipsis text-[12px] text-[#89939C]'>
                        <p className='!leading-4 text-nowrap overflow-hidden text-ellipsis'>{data?.sub_title}</p>
                    </div>

                    <div className='flex justify-start items-center mt-[0.5rem]'>
                    {(data?.text == 1) && (
                        <svg className='mx-[0.2rem]' width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9201 1.75C16.6711 1.75 18.5191 3.63387 18.5191 6.43738V14.4839C18.5191 17.3119 16.728 19.1511 13.956 19.1686L7.22463 19.1712C4.47363 19.1712 2.62476 17.2874 2.62476 14.4839V6.43738C2.62476 3.6085 4.41588 1.77012 7.18788 1.7535L13.9193 1.75H13.9201ZM13.9201 3.0625L7.19226 3.066C5.15526 3.07825 3.93726 4.33825 3.93726 6.43738V14.4839C3.93726 16.597 5.16663 17.8587 7.22376 17.8587L13.9516 17.8561C15.9886 17.8439 17.2066 16.5821 17.2066 14.4839V6.43738C17.2066 4.32425 15.9781 3.0625 13.9201 3.0625ZM13.7513 13.5395C14.1136 13.5395 14.4076 13.8335 14.4076 14.1957C14.4076 14.558 14.1136 14.852 13.7513 14.852H7.43384C7.07159 14.852 6.77759 14.558 6.77759 14.1957C6.77759 13.8335 7.07159 13.5395 7.43384 13.5395H13.7513ZM13.7513 9.8763C14.1136 9.8763 14.4076 10.1703 14.4076 10.5325C14.4076 10.8948 14.1136 11.1888 13.7513 11.1888H7.43384C7.07159 11.1888 6.77759 10.8948 6.77759 10.5325C6.77759 10.1703 7.07159 9.8763 7.43384 9.8763H13.7513ZM9.84421 6.2216C10.2065 6.2216 10.5005 6.5156 10.5005 6.87785C10.5005 7.2401 10.2065 7.5341 9.84421 7.5341H7.43358C7.07133 7.5341 6.77733 7.2401 6.77733 6.87785C6.77733 6.5156 7.07133 6.2216 7.43358 6.2216H9.84421Z" fill="black"/>
                        </svg>
                    )}

                    {(data?.image == 1) && (
                        <svg className='mx-[0.2rem]' width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="6.907" cy="15.2728" r="2.63333" stroke="black" stroke-width="1.4"/>
                        <path d="M9.40698 14.0234V4.02344C9.40698 3.33308 9.96663 2.77344 10.657 2.77344V2.77344C11.3473 2.77344 11.907 3.33308 11.907 4.02344V4.37344C11.907 5.25709 12.6233 5.97344 13.507 5.97344H13.5736" stroke="black" stroke-width="1.4" stroke-linecap="round"/>
                        <path d="M12.7404 8.60742H18.5737" stroke="black" stroke-width="1.4" stroke-linecap="round"/>
                        <path d="M12.7404 11.9395H18.5737" stroke="black" stroke-width="1.4" stroke-linecap="round"/>
                        <path d="M12.7404 15.2734H18.5737" stroke="black" stroke-width="1.4" stroke-linecap="round"/>
                        </svg>
                    )}

                    {(data?.audio == 1) && (
                        <svg className='mx-[0.2rem]' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3622_11536)">
                        <path d="M12.8062 6.37668C12.8062 6.37668 14.9186 8.48908 12.8062 11.4464M14.4961 3.8418C15.9043 5.53172 17.8759 9.92552 14.4961 13.9813" stroke="#212529" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2.37689 6.74654L3.68467 7.0081C4.25843 7.12285 4.85157 6.94326 5.26531 6.52952L8.24095 3.55388C8.71342 3.08141 9.52128 3.41603 9.52128 4.08421V14.1008C9.52128 14.7416 8.76969 15.0872 8.28319 14.6702L5.21423 12.0397C4.80595 11.6897 4.25944 11.5469 3.73214 11.6524L2.37689 11.9234C1.6034 12.0781 0.881744 11.4865 0.881744 10.6977V7.97227C0.881744 7.18346 1.6034 6.59184 2.37689 6.74654Z" stroke="#212529" stroke-width="1.5"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_3622_11536">
                        <rect width="16.8992" height="16.8992" fill="white" transform="translate(0.131744 0.462891)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    )}

                    {(data?.video == 1) && (
                        <svg className='mx-[0.2rem]' width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1454_29800)">
                        <path d="M2.69769 12.9813V8.8418C2.69769 7.18494 4.04084 5.8418 5.69769 5.8418H10.6822C12.339 5.8418 13.6822 7.18494 13.6822 8.8418V12.9813C13.6822 14.6382 12.339 15.9813 10.6822 15.9813H5.69769C4.04084 15.9813 2.69769 14.6382 2.69769 12.9813Z" stroke="#212529" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.3925 7.5962L14.6532 8.63978C14.0508 9.00123 13.6822 9.65224 13.6822 10.3548V11.4698C13.6822 12.1724 14.0508 12.8234 14.6532 13.1848L16.3925 14.2284C17.059 14.6283 17.907 14.1482 17.907 13.3709V10.9123V8.45369C17.907 7.6764 17.059 7.19628 16.3925 7.5962Z" stroke="#212529" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1454_29800">
                        <rect width="16.8992" height="16.8992" fill="white" transform="translate(1.85272 2.46289)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    )}

                    {(data?.premium == 1) && (
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.2778 4.69513C9.79019 3.46319 11.5354 3.46319 12.0478 4.69513L12.7411 6.36201C12.9571 6.88137 13.4455 7.23622 14.0062 7.28117L15.8057 7.42544C17.1357 7.53206 17.675 9.19183 16.6617 10.0598L15.2906 11.2343C14.8634 11.6002 14.6769 12.1744 14.8074 12.7215L15.2263 14.4776C15.5358 15.7754 14.124 16.8012 12.9853 16.1057L11.4447 15.1647C10.9646 14.8715 10.3609 14.8715 9.8809 15.1647L8.34024 16.1057C7.20159 16.8012 5.78971 15.7754 6.09929 14.4776L6.51818 12.7215C6.64869 12.1744 6.46213 11.6002 6.03495 11.2343L4.66388 10.0598C3.65058 9.19183 4.18987 7.53206 5.51985 7.42544L7.31939 7.28117C7.88008 7.23622 8.36849 6.88137 8.5845 6.36201L9.2778 4.69513Z" fill="#4ECDC4"/>
                        </svg>
                    )}
                    </div>
                </div>

                <div className='flex justify-center items-center flex-col h-full'>
                    <div className='w-[2.5rem] h-[2.5rem] min-w-[2.5rem]'>
                        <img className='w-full h-full rounded-full' src={data?.author?.avatar} />
                    </div>

                    <div className='w-full flex flex-col items-center'>
                        <p className='text-[#2E2323] text-[8px] text-nowrap overflow-hidden !leading-4'>{data?.author?.name}</p>
                    </div>
                </div>
            </button>
        </>
    );
};

export default PieceBox;