import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import ArticlesBox from '../../Components/Leyouts/Articles/ArticlesBox';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider, useInfiniteQuery,useQuery } from 'react-query';
import toast, { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';
import i1 from './../../Assets/Images/Empty/i1.png';
import i2 from './../../Assets/Images/Empty/i2.png';
import i3 from './../../Assets/Images/Empty/i3.png';
import i4 from './../../Assets/Images/Empty/i4.png';
import i5 from './../../Assets/Images/Empty/i5.png';
import i6 from './../../Assets/Images/Empty/i6.png';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: '2.65rem',
        width: '100%',
        backgroundColor: '#F5F5F5',
        borderColor: state.isFocused ? 'none' : 'none',
        borderRadius: '8px',
        boxShadow: state.isFocused ? 'none' : 'none',
        '&:hover': {
            borderColor: 'none',
        },
    }),

    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        padding: '0 8px',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '100%',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 999,
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: '400px !important',
        minHeight: '400px !important',
        overflowY: 'auto',
    }),
};

const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
};

const fetchData = async (page = 1, categoryid, query,instruments) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    let instrumentsQuery = '';
    if (instruments) {
        instrumentsQuery = `&instruments[0]=${instruments}`;
    }


    let categoryQuery = '';
    if (categoryid && categoryid !== "all") {
        categoryQuery = `&categories[0]=${categoryid}`;
    }

    let searchQuery = '';
    if (query) {
        searchQuery = `&search=${query}`;
    }

    const response = await fetch(`https://backend.neireez.com/v1/posts?per_page=10&page=${page}${categoryQuery}${searchQuery}${instrumentsQuery}`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
};

const fetchCategory = async (instruments) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };
    

    let instrumentsQuery = '';
    if (instruments) {
        instrumentsQuery = `&instrument=${instruments}`;
    }

    const response = await fetch(`https://backend.neireez.com/v1/categories?type=post&per_page=30&subject=posts${instrumentsQuery}`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
};

const fetchmetaTga = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/settings?names[0]=title&names[1]=logo&names[2]=keywords&names[3]=description`, requestOptions);
    if (!response.ok) {

    }
    return response.json();
};

const fetchIstruments = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/instruments`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch meta tags');
    }
    return response.json();
};

const Articles = () => {
    const [categoryid, setCategoryid] = useState('all');
    const [bottomReached, setBottomReached] = useState(false);

    const queryParams = useQueryParams();
    const [query, setQuery] = useState();
    const location = useLocation();
    useEffect(() => {
        setQuery(queryParams.get('q'));
    }, [location])

    const clear = () => {
        setQuery(null);
    }

    const categoryQ = queryParams.get('category');
    const [optionsSaz, setOptionsSaz] = useState([]);

    const [instrumentID, setInstrumentID] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        localStorage.setItem('instrumentID', null);
    };

    const { data : metaTga, errorMetatag, isLoadingMetatag } = useQuery(['metaTga'], fetchmetaTga);
    const { data: lastPosts, fetchNextPage, refetch, hasNextPage, isLoading, isError, error } = useInfiniteQuery(
        ['lastPosts', categoryid, query, instrumentID],
        ({ pageParam = 1 }) => fetchData(pageParam, categoryid, query,instrumentID),
        {
            getNextPageParam: (lastPage) => 
                lastPage.meta.current_page < lastPage.meta.last_page 
                    ? lastPage.meta.current_page + 1 
                    : null,
            refetchOnWindowFocus: false,
        }
    );

    const { data : category, isLoadingCategory, errorSlider } = useQuery(['data', 'categoryA',instrumentID], () => fetchCategory(instrumentID),{
            retry: false,
            refetch: false,
            refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (categoryQ && category) {
            setCategoryid(categoryQ);
        }
    }, [categoryQ,category])

    useEffect(() => {
        const handleScroll = () => {
            const footer = document.getElementsByTagName('footer')[0];
            const footerHeight = footer?.offsetHeight || 0;
            const offset = 150;

            const isBottom =
                window.innerHeight + window.scrollY >= document.body.offsetHeight - footerHeight - offset;

            setBottomReached(isBottom);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (bottomReached && hasNextPage && !isLoading) {
            fetchNextPage();
        }
    }, [bottomReached, fetchNextPage, hasNextPage, isLoading]);

    useEffect(() => {
        if (categoryid) {
            refetch();
        }
    }, [categoryid, refetch]);

    const changeCategory= (e) => {
        setCategoryid(e);
        navigate(`?category=${e}`);
    }

    const [searchQ, setsearchQ] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const navigate = useNavigate();

    const handelSearchLink = (q) => {
        document.getElementById('input-search-main').value = '';
        document.getElementById('input-search-main').blur();
        setIsFocused(false);
        setsearchQ("");
        navigate(q);
    }

    const { data: instruments, isError: errornIstruments, isLoading: isLoadingIstruments } = useQuery(
        'instruments',
        fetchIstruments
    );

    useEffect(() => {
        const formattedOptions = instruments?.data?.map((item) => ({
            value: item?.id,
            label: `${item?.title}`,
        }));

        setOptionsSaz(formattedOptions);
    }, [instruments?.data]);

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    const user = useSelector((state) => state.user);

    useEffect(() => {
        const findInstrumentById = (id) => instruments?.data?.find((item) => item?.id === Number(id));
        const findInstrumentByTitle = (title) => instruments?.data?.find((item) => item?.title === title);
    
        const updateSelectedInstrument = (instrument) => {
            if (instrument) {
                setInstrumentID(instrument.id);
                setSelectedOption({ value: instrument.id, label: instrument.title });
            }
        };
    
        const savedInstrumentID = localStorage.getItem('instrumentID');
        const userInstrumentTitle = user?.profile?.data?.instrument;
    
        if (savedInstrumentID) {
            if (savedInstrumentID) {
                updateSelectedInstrument(findInstrumentById(savedInstrumentID));
            } else if(instrumentID != null) {
                openModal();
            }
        } else {
            const selectedInstrument = findInstrumentByTitle(userInstrumentTitle);
            if (selectedInstrument) {
                updateSelectedInstrument(selectedInstrument);
            } else {
                openModal();
            }
        }
    }, [user, instruments]);

    const handleSazChange = (selectedOption) => {
        const newInstrumentID = selectedOption?.value;
        setInstrumentID(newInstrumentID);
        setSelectedOption(selectedOption);
    
        if (newInstrumentID) {
            localStorage.setItem('instrumentID', newInstrumentID);
        }
    };

    const [dropdownPosition, setDropdownPosition] = useState(null);
    const buttonRef = useRef(null);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        if (dropdownPosition) {
            setDropdownPosition(null);
        } else if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setDropdownPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
                width: rect.width,
            });
        }
    };

    const closeDropdown = (e) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(e.target) &&
            buttonRef.current &&
            !buttonRef.current.contains(e.target)
        ) {
            setDropdownPosition(null);
        }
    };

    useEffect(() => {
        document.addEventListener('click', closeDropdown);
        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, []);
    

    const swiperCategory = useRef(null);
    useEffect(() => {
        if (categoryid && categoryQ !== "all") {
            const number = category?.data?.findIndex((item) => item?.id === Number(categoryid));
            if (window.innerWidth <= 768 && number !== -1 && swiperCategory.current && category?.data?.length > 6) {
                swiperCategory.current.slideTo(number);
            }

            if (window.innerWidth >= 768 && number !== -1 && swiperCategory.current && category?.data?.length > 15) {
                swiperCategory.current.slideTo(number);
            }
        }
    }, [categoryid, categoryQ, category]);

    return (
        <>
            <Helmet>
                <title>گنجه - نیریز</title>
                <meta name="keywords" content={metaTga?.keywords} />
                <meta name="description" content={metaTga?.description} />
                <meta property="og:title" content="گنجه - نیریز" />
                <meta property="og:description" content={metaTga?.description} />
            </Helmet>

            <main id='blog-main'>
                <section>
                    <div className='flex flex-col lg:flex-row justify-between items-center px-[1.5rem] lg:px-[4rem] pt-[2rem]'>
                        <div className='w-full lg:w-max'>
                            <h4 className='text-[24px] lg:text-[28px] font-[700] text-nowrap font-bold'>{(query) ? `نتایج جستجو برای “${query}”` : 'مقالات'}</h4>
                        </div>

                        <div className='w-full lg:w-[60%] mx-[2rem] flex justify-center items-center'>
                            <div className='relative flex justify-center items-center w-full h-[2.5rem] mt-[1rem] lg:mt-0'>
                                <input
                                    id='input-search-main'
                                    onChange={(e) => setsearchQ(e.target.value)}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                    autoComplete="off"
                                    className='h-full text-[14px] bg-[#F7F7F7] border border-[#E0E0E0] w-full rounded-[0.5rem] pr-[3.5rem] pl-[0.5rem] py-[0.5rem]'
                                    type='text'
                                    placeholder='دنبال چه می گردید؟ '
                                />

                                <svg className='absolute right-[1rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5C18 12.205 17.4311 13.7771 16.4727 15.0368L20.842 19.2829L19.4481 20.7171L15.0613 16.454C13.7975 17.4237 12.216 18 10.5 18ZM16 10.5C16 13.5376 13.5376 16 10.5 16C7.46243 16 5 13.5376 5 10.5C5 7.46243 7.46243 5 10.5 5C13.5376 5 16 7.46243 16 10.5Z" fill="#424750"/>
                                </svg>

                                {(isFocused && searchQ) && (
                                    <div className='box-more-search-header' onMouseDown={(e) => e.preventDefault()}>
                                        <button onClick={(e) => handelSearchLink(`/pieces?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem] border-b border-[#EEEEEE]'>
                                            نمایش “{searchQ}” در قطعات
                                        </button>

                                        <button onClick={(e) => handelSearchLink(`/theories?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem]'>
                                            نمایش “{searchQ}” در تئوری موسیقی نیریز
                                        </button>
                                        
                                        <button onClick={(e) => handelSearchLink(`/articles?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem]'>
                                            نمایش “{searchQ}” در مقالات
                                        </button>

                                        <button onClick={(e) => handelSearchLink(`/references?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem]'>
                                            نمایش “{searchQ}” در منابع
                                        </button>
                                    </div>
                                )}
                            </div>

                            {(query) && (
                                <button onClick={(e) => clear()} className='btn-click text-nowrap h-[2.5rem] mt-[1rem] mr-[1rem] cursor-pointer rounded-full !w-max !flex lg:!hidden justify-center items-center'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.99967 11.1673L5.91634 15.2507C5.76356 15.4034 5.56912 15.4798 5.33301 15.4798C5.0969 15.4798 4.90245 15.4034 4.74967 15.2507C4.5969 15.0979 4.52051 14.9034 4.52051 14.6673C4.52051 14.4312 4.5969 14.2368 4.74967 14.084L8.83301 10.0007L4.74967 5.91732C4.5969 5.76454 4.52051 5.5701 4.52051 5.33398C4.52051 5.09787 4.5969 4.90343 4.74967 4.75065C4.90245 4.59787 5.0969 4.52148 5.33301 4.52148C5.56912 4.52148 5.76356 4.59787 5.91634 4.75065L9.99967 8.83398L14.083 4.75065C14.2358 4.59787 14.4302 4.52148 14.6663 4.52148C14.9025 4.52148 15.0969 4.59787 15.2497 4.75065C15.4025 4.90343 15.4788 5.09787 15.4788 5.33398C15.4788 5.5701 15.4025 5.76454 15.2497 5.91732L11.1663 10.0007L15.2497 14.084C15.4025 14.2368 15.4788 14.4312 15.4788 14.6673C15.4788 14.9034 15.4025 15.0979 15.2497 15.2507C15.0969 15.4034 14.9025 15.4798 14.6663 15.4798C14.4302 15.4798 14.2358 15.4034 14.083 15.2507L9.99967 11.1673Z" fill="#212121"/>
                                    </svg>

                                    <p className='text-[12px] text-[#212121] font-[600] mr-[0.3rem]'> حذف فیلتر</p>
                                </button>
                            )}
                        </div>
                    </div>

                    <section>
                        <Swiper
                            pagination={{
                                clickable: true,
                            }}
                            className="slider-tags-blogs mt-[1rem] py-[5px]"
                            slidesPerView={'auto'}
                            onSwiper={(swiper) => (swiperCategory.current = swiper)}
                            grabCursor={true}
                            spaceBetween={16}
                            breakpoints={{
                                1080: {
                                    slidesOffsetAfter: 32,
                                    slidesOffsetBefore: 32,
                                },
                                0: {
                                    slidesOffsetAfter: 24,
                                    slidesOffsetBefore: 24,
                                },
                            }}
                            speed={500}
                        >
                            {isLoadingCategory ? (
                                    <>
                                        <SwiperSlide className="!w-max">
                                            <div className='relative w-[100px] h-[40px] rounded-[12px] bg-[#e0e0e0] overflow-hidden'>
                                                <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                                <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                            </div>
                                        </SwiperSlide>
                                        
                                        <SwiperSlide className="!w-max">
                                            <div className='relative w-[100px] h-[40px] rounded-[12px] bg-[#e0e0e0] overflow-hidden'>
                                                <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                                <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                            </div>
                                        </SwiperSlide>
                                    </>
                            ) : (
                                <>
                                    <SwiperSlide className="!w-max relative">
                                        <button
                                            ref={buttonRef}
                                            onClick={toggleDropdown}
                                            className="w-full rounded-[16px] px-[16px] py-[0.25rem] border-[2px] text-black border-black flex justify-center items-center"
                                        >
                                            <p>مقالات</p>

                                            <svg className='mr-[0.2rem]' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00002 9.96746C7.91113 9.96746 7.8278 9.95346 7.75002 9.92546C7.67224 9.89791 7.60002 9.8508 7.53335 9.78413L4.45002 6.7008C4.3278 6.57858 4.26958 6.42569 4.27535 6.24213C4.28069 6.05902 4.34447 5.90635 4.46669 5.78413C4.58891 5.66191 4.74447 5.6008 4.93335 5.6008C5.12224 5.6008 5.2778 5.66191 5.40002 5.78413L8.00002 8.38413L10.6167 5.76746C10.7389 5.64524 10.8918 5.5868 11.0754 5.59213C11.2585 5.59791 11.4111 5.66191 11.5334 5.78413C11.6556 5.90635 11.7167 6.06191 11.7167 6.2508C11.7167 6.43969 11.6556 6.59524 11.5334 6.71746L8.46669 9.78413C8.40002 9.8508 8.3278 9.89791 8.25002 9.92546C8.17224 9.95346 8.08891 9.96746 8.00002 9.96746Z" fill="#212121"/>
                                            </svg>
                                        </button>
                                    </SwiperSlide>

                                    <SwiperSlide className="!w-max">
                                        <button onClick={(e) => changeCategory('all')} className={`w-full rounded-[12px] px-[8px] py-[4px] border-[2px] ${('all' == categoryid) ? 'bg-[#EEF8F6] border-[#089584]' : 'border-[#F0F0F0]'}`}>
                                            <p>همه</p>
                                        </button>
                                    </SwiperSlide>
                                    
                                    {category?.data && Array.isArray(category?.data) ? (
                                        category?.data.map((data,index) => (
                                            <SwiperSlide className="!w-max" key={Math.random()}>
                                                <button onClick={(e) => changeCategory(data?.id)} className={`w-full rounded-[12px] px-[8px] py-[4px] border-[2px] ${(data?.id == categoryid) ? 'bg-[#EEF8F6] border-[#089584]' : 'border-[#F0F0F0]'}`}>
                                                    <p>{ data?.title }</p>
                                                </button>
                                            </SwiperSlide>
                                        ))
                                    ) : (
                                        <p></p>
                                    )}

                                    {(query) && (
                                        <SwiperSlide onClick={(e) => clear()} className='btn-click cursor-pointer rounded-full py-[4px] !w-max !hidden lg:!flex justify-center items-center'>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.99967 11.1673L5.91634 15.2507C5.76356 15.4034 5.56912 15.4798 5.33301 15.4798C5.0969 15.4798 4.90245 15.4034 4.74967 15.2507C4.5969 15.0979 4.52051 14.9034 4.52051 14.6673C4.52051 14.4312 4.5969 14.2368 4.74967 14.084L8.83301 10.0007L4.74967 5.91732C4.5969 5.76454 4.52051 5.5701 4.52051 5.33398C4.52051 5.09787 4.5969 4.90343 4.74967 4.75065C4.90245 4.59787 5.0969 4.52148 5.33301 4.52148C5.56912 4.52148 5.76356 4.59787 5.91634 4.75065L9.99967 8.83398L14.083 4.75065C14.2358 4.59787 14.4302 4.52148 14.6663 4.52148C14.9025 4.52148 15.0969 4.59787 15.2497 4.75065C15.4025 4.90343 15.4788 5.09787 15.4788 5.33398C15.4788 5.5701 15.4025 5.76454 15.2497 5.91732L11.1663 10.0007L15.2497 14.084C15.4025 14.2368 15.4788 14.4312 15.4788 14.6673C15.4788 14.9034 15.4025 15.0979 15.2497 15.2507C15.0969 15.4034 14.9025 15.4798 14.6663 15.4798C14.4302 15.4798 14.2358 15.4034 14.083 15.2507L9.99967 11.1673Z" fill="#212121"/>
                                            </svg>

                                            <p className='text-[12px] text-[#212121] font-[600] mr-[0.3rem]'> حذف فیلتر</p>
                                        </SwiperSlide>
                                    )}
                                </>
                            )}
                        </Swiper>

                        {dropdownPosition &&
                            createPortal(
                                <div
                                    ref={dropdownRef}
                                    className="absolute bg-white shadow-lg rounded-[8px] border border-[#E0E0E0] z-[1000]"
                                    style={{
                                        position: 'absolute',
                                        top: `${dropdownPosition.top + 8}px`,
                                        left: `${dropdownPosition.left - 10}px`,
                                        width: `${dropdownPosition.width + 10}px`,
                                    }}
                                >
                                    <ul>
                                        <li className="hover:bg-gray-100 cursor-pointer" onClick={() => setDropdownPosition(null)} >
                                            <Link className='w-full px-[8px] text-[14px] py-[4px] flex text-nowrap' to={'/pieces'}>قطعات</Link>
                                        </li>

                                        {/* <li className="hover:bg-gray-100 cursor-pointer" onClick={() => setDropdownPosition(null)} >
                                            <Link className='w-full px-[8px] text-[14px] py-[4px] flex text-nowrap' to={'/articles'}>مقالات</Link>
                                        </li> */}

                                        <li className="hover:bg-gray-100 cursor-pointer" onClick={() => setDropdownPosition(null)} >
                                            <Link className='w-full px-[8px] text-[14px] py-[4px] flex text-nowrap' to={'/theory'}>تئوری موسیقی</Link>
                                        </li>

                                        <li className="hover:bg-gray-100 cursor-pointer" onClick={() => setDropdownPosition(null)} >
                                            <Link className='w-full px-[8px] text-[14px] py-[4px] flex text-nowrap' to={'/references'}>منابع</Link>
                                        </li>
                                    </ul>
                                </div>,
                                document.body
                            )}
                    </section>
                </section>

                <section className='mb-[2rem] px-[1.5rem] lg:px-[4rem]'>
                    {isLoading ? (
                        <>
                            <section className='flex justify-center items-center flex-col lg:flex-row my-[2rem]'>
                                <div className='w-full relative lg:w-[50%] lg:ml-[1rem] h-[200px] lg:h-[400px] rounded-[0.5rem] bg-[#e0e0e0] overflow-hidden'>
                                    <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                    <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                </div>

                                <div className='w-full lg:w-[50%] lg:mr-[1rem] mt-[1rem] h-full'>
                                    <div className='mb-[1rem] relative h-[38px] bg-[#e0e0e0] overflow-hidden rounded-[0.5rem]'>
                                        <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                        <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                    </div>

                                    <div className='flex justify-start items-center mb-[1rem]'>
                                        <div className='w-[3.5rem] h-[3.5rem] min-w-[3.5rem] lg:w-[4rem] lg:h-[4rem] lg:min-w-[4rem] bg-[#e0e0e0] overflow-hidden rounded-full relative'>
                                            <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                            <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                        </div>

                                        <div className='w-full flex-row lg:flex-col flex justify-between lg:justify-center items-start mr-[12px]'>
                                            <p className='text-[#3C3C3C] w-[50%] h-[3.5rem] min-w-[3.5rem] lg:h-[4rem] bg-[#e0e0e0] overflow-hidden rounded-[0.5rem] relative'>
                                                <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                                <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                            </p>
                                        </div>
                                    </div>

                                    <div className='lg:h-[190px] bg-[#e0e0e0] overflow-hidden rounded-[0.5rem] relative'>
                                        <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                        <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                    </div>

                                    <div className='mt-[1rem]'>
                                        <div className='flex justify-center items-center w-full lg:w-[150px] h-[48px] bg-[#e0e0e0] overflow-hidden rounded-[0.5rem] relative'>
                                            <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                            <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                        </div>
                                    </div>
                                </div>      
                            </section>
                        </>
                    ) : (
                        <>
                            {lastPosts?.pages?.[0]?.data?.length > 0 ? (
                                <>
                                    {lastPosts && lastPosts?.pages.map(pageData => (
                                        <React.Fragment key={pageData.pageNumber}>
                                            {pageData?.data.map((data,index) => (
                                                <ArticlesBox data={data} key={Math.random()}/>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </>
                            ) : (
                                <div className='w-full py-[5rem] flex justify-center items-center flex-col'>
                                    {(selectedOption?.value == 1 || selectedOption == 2 || selectedOption == 10) ? (
                                        <img className='max-w-[15rem]' src={i1} />
                                    ) : (selectedOption?.value == 8) ? (
                                        <img className='max-w-[15rem]' src={i3} />
                                    ) : (selectedOption?.value == 6) ? (
                                        <img className='max-w-[15rem]' src={i4} />
                                    ) : (selectedOption?.value == 11 || selectedOption?.value == 12 || selectedOption?.value == 13) ? (
                                        <img className='max-w-[15rem]' src={i2} />
                                    ) : (selectedOption?.value == 3) ? ( 
                                        <img className='max-w-[15rem]' src={i5} />
                                    ) : (
                                        <img className='max-w-[15rem]' src={i6} />
                                    )}

                                    <p className='mt-[2rem]'>نتیجه ای یافت نشد.</p>

                                    <Link to={'/chest'} className='text-[#089584] text-[12px] lg:text-[14px] px-[0.75rem] py-[0.5rem] lg:px-[1.5rem] lg:py-[0.75rem] bg-[#dcf8f3] rounded-[0.5rem] mt-[1rem]'>بازگشت به گنجه</Link>
                                </div>
                            )}
                        </>
                    )}
                </section>

                <div className={`fixed z-[50000] right-0 bottom-0 w-[100vw] h-[100dvh] flex items-end items-center justify-center transition-opacity duration-300 ${isModalOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`} onClick={handleOverlayClick}>
                    <div className={`relative z-[50010] rounded-[1rem] transform transition-all ${isModalOpen ? 'scale-100' : 'scale-95'}`}>
                        <div id='modal-container' className="bg-white p-[1rem] h-auto max-h-[85vh] overflow-y-auto lg:h-auto w-[90vw] lg:w-[30rem] relative rounded-[1rem]">
                            <div className='flex justify-between items-center border-b border-[#EEEEEE] pb-[0.5rem] mb-[1rem]'>
                                <p className='text-[#0C0C0C] text-[16px] font-[600]'>تکمیل اطلاعات شخصی</p>

                                <button onClick={closeModal}>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.3642 7.54996L16.9499 6.13574L12.0002 11.0855L7.05044 6.13574L5.63623 7.54996L10.586 12.4997L5.63623 17.4495L7.05044 18.8637L12.0002 13.9139L16.9499 18.8637L18.3642 17.4495L13.4144 12.4997L18.3642 7.54996Z" fill="#9E9E9E"/>
                                    </svg>
                                </button>
                            </div>

                            <div>
                                <div>
                                    <p className='text-[14px] mb-[0.25rem]'>نام سازی که می‌نوازید را انتخاب کنید</p>
                                </div>

                                <div className='w-full relative'>
                                    <Select
                                        value={selectedOption}
                                        options={optionsSaz}
                                        onChange={handleSazChange}
                                        placeholder="انتخاب ساز"
                                        styles={{
                                            ...customStyles,
                                            menuPortal: (provided) => ({ ...provided, zIndex: 50050 }),
                                        }}
                                        menuPortalTarget={document.body}
                                        isSearchable={false}
                                    />
                                </div>
                            </div>

                            <div className='w-full mt-[1rem]'>
                                <button onClick={closeModal} className='btn-hover flex justify-center items-center bg-green py-[0.5rem] px-[1rem] rounded-[0.5rem] text-white w-full'>
                                    <span className='mr-[0.3rem]'>ثبت و ادامه</span>
                                </button> 
                            </div>
                        </div>
                    </div>

                    <div onClick={closeModal} className={`fixed z-[50000] right-0 top-0 w-[100vw] h-[100vh]  bg-black opacity-[0.6] bg-opacity-75 transition-opacity duration-300 ${isModalOpen ? 'opacity-100' : 'opacity-0'}`} aria-hidden="true"></div>
                </div>
            </main>
        </>
    );
};

export default Articles;