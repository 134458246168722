import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import seasontable from './../../Assets/Databases/seasontable.json';
import lessontable from './../../Assets/Databases/lessontable.json';
import booklet from './../../Assets/Databases/booklet.json';
import { useLocation,Link, useNavigate } from 'react-router-dom';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import { createPortal } from 'react-dom';

const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
};

const Theory = () => {
    const [seasen, setSeasen] = useState(1);
    const [chapter, setChapter] = useState(1);
    const [lesson, setLesson] = useState(1);
    const [lessonMobile, setLessonMobile] = useState(null);

    const queryParams = useQueryParams();
    const chapterQ = queryParams.get('chapter');
    const seasonQ = queryParams.get('season');
    const lessonQ = queryParams.get('lesson');
    
    useEffect(() => {
        if(seasonQ != null)
        {
            setSeasen(Number(seasonQ));
        }

        if(chapterQ != null)
        {
            setChapter(Number(seasonQ));
            setLesson(Number(chapterQ));
            
            if (window.innerWidth < 1023) {
                setLessonMobile(Number(chapterQ));
            }
        } 
        

        if(lessonQ != null)
        {
            setTimeout(() => {
                const targetElement = document.getElementById(`lesson-${lessonQ}`);
                if (targetElement) {
                    const headerOffset = 150;
                    const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
                    const offsetPosition = elementPosition - headerOffset;
            
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth',
                    });
                }
            }, 1000);

            if (window.innerWidth < 1023) {
                setTimeout(() => {
                    const targetElement = document.getElementById(`modal-lesson-${lessonQ}`);
                    
                    if (targetElement) {
                        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                }, 1000);
            }
        }
    }, [])

    useEffect(() => {
        const initializePlyr = () => {
            const players = document.querySelectorAll('.player');
            players.forEach(media => {
                if (!media.plyr) {
                    new Plyr(media, {
                        controls: ['play', 'progress', 'current-time'],
                        seekTime: 15,
                        speed: { selected: 1, options: [0.5, 1, 1.5, 2] },
                        invertTime: false,
                    });
                }
            });
        };

        initializePlyr();

        return () => {
        };
    }, [chapter, lesson]);
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => {
        setLessonMobile(null);
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (isModalOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isModalOpen]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };
    

    const chengeSeasen = (id) => {
        if(id == seasen)
        {
            setSeasen(0);
            setChapter(1);
        }else{
            setSeasen(id);
            setChapter(id);
        }
        setLesson(1);
        window.scrollTo(0, 0);
    }

    const chengeLesson = (id,idChapter) => {
        setLesson(id);
        setChapter(idChapter);
        if (window.innerWidth <= 1023) {
            setLessonMobile(id);
        }
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        if(lessonMobile != null)
        {
            setIsModalOpen(true);
            document.getElementById('modal-container').scrollTop = 0;
        }
    }, [lessonMobile]);

    useEffect(() => {
        const handleScroll = () => {
            const headerH = document.getElementById('header-home').offsetHeight;
            document.getElementById('sidbar-theory').style.top = headerH + 'px';
            document.getElementById('sidbar-theory').style.height =  window.innerHeight - headerH + 'px';
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const [query, setQuery] = useState();
    const location = useLocation();
    useEffect(() => {
        setQuery(queryParams.get('q'));
    }, [location])

    const clear = () => {
        setQuery(null);
    }

    const [searchQ, setsearchQ] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const navigate = useNavigate();

    const handelSearchLink = (q) => {
        document.getElementById('input-search-main').value = '';
        document.getElementById('input-search-main').blur();
        setIsFocused(false);
        setsearchQ("");
        navigate(q);
    }

    const [dropdownPosition, setDropdownPosition] = useState(null);
    const buttonRefs = useRef([]);
    const dropdownRef = useRef(null);

    const toggleDropdown = (index) => {
        if (dropdownPosition?.index === index) {
            setDropdownPosition(null);
        } else if (buttonRefs.current[index]) {
            const rect = buttonRefs.current[index].getBoundingClientRect();
            setDropdownPosition({
                index,
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
                width: rect.width,
            });
        }
    };

    const closeDropdown = (e) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(e.target) &&
            !buttonRefs.current.some((ref) => ref?.contains(e.target))
        ) {
            setDropdownPosition(null);
        }
    };

    useEffect(() => {
        document.addEventListener('click', closeDropdown);
        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>{'تئوری موسیفی - نیریز'}</title>
                <meta name="keywords" content={'تئوری موسیفی - نیریز'} />
                <meta name="description" content={'تئوری موسیفی - نیریز'} />
                <meta property="og:title" content={'تئوری موسیفی - نیریز'} />
                <meta property="og:description" content={'تئوری موسیفی - نیریز'} />
            </Helmet>
            
            <main>
                <section className='px-[1.5rem] lg:px-[4rem] pt-[2rem] flex flex-col lg:flex-row justify-between items-center'>
                    <div className='w-full lg:w-max text-nowrap'>
                        <h3 className='text-[28px] text-[#0C0C0C] font-[700]'>کتابچه تئوری موسیقی</h3>
                    </div>

                    <div className='flex justify-center items-center flex-row-reverse w-full mt-[1rem] lg:mt-0'>
                        <div className='w-full lg:mr-[2rem] flex justify-center items-center'>
                            <div className="!w-max relative hidden lg:block ml-[2rem]">
                                <button
                                    ref={(el) => (buttonRefs.current[1] = el)} 
                                    onClick={() => toggleDropdown(1)}
                                    className="w-full rounded-[16px] text-nowrap px-[16px] py-[0.25rem] border-[2px] text-black border-black flex justify-center items-center"
                                >
                                    <p>تئوری موسیقی</p>

                                    <svg className='mr-[0.2rem]' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.00002 9.96746C7.91113 9.96746 7.8278 9.95346 7.75002 9.92546C7.67224 9.89791 7.60002 9.8508 7.53335 9.78413L4.45002 6.7008C4.3278 6.57858 4.26958 6.42569 4.27535 6.24213C4.28069 6.05902 4.34447 5.90635 4.46669 5.78413C4.58891 5.66191 4.74447 5.6008 4.93335 5.6008C5.12224 5.6008 5.2778 5.66191 5.40002 5.78413L8.00002 8.38413L10.6167 5.76746C10.7389 5.64524 10.8918 5.5868 11.0754 5.59213C11.2585 5.59791 11.4111 5.66191 11.5334 5.78413C11.6556 5.90635 11.7167 6.06191 11.7167 6.2508C11.7167 6.43969 11.6556 6.59524 11.5334 6.71746L8.46669 9.78413C8.40002 9.8508 8.3278 9.89791 8.25002 9.92546C8.17224 9.95346 8.08891 9.96746 8.00002 9.96746Z" fill="#212121"/>
                                    </svg>
                                </button>
                            </div>

                            {dropdownPosition &&
                                createPortal(
                                    <div
                                        ref={dropdownRef}
                                        className="absolute bg-white shadow-lg rounded-[8px] border border-[#E0E0E0] z-[1000]"
                                        style={{
                                            position: 'absolute',
                                            top: `${dropdownPosition.top + 8}px`,
                                            left: `${dropdownPosition.left - 10}px`,
                                            width: `${dropdownPosition.width + 10}px`,
                                        }}
                                    >
                                        <ul>
                                            <li className="hover:bg-gray-100 cursor-pointer" onClick={() => setDropdownPosition(null)} >
                                                <Link className='w-full px-[8px] text-[14px] py-[4px] flex text-nowrap' to={'/pieces'}>قطعات</Link>
                                            </li>

                                            <li className="hover:bg-gray-100 cursor-pointer" onClick={() => setDropdownPosition(null)} >
                                                <Link className='w-full px-[8px] text-[14px] py-[4px] flex text-nowrap' to={'/articles'}>مقالات</Link>
                                            </li>

                                            {/* <li className="hover:bg-gray-100 cursor-pointer" onClick={() => setDropdownPosition(null)} >
                                                <Link className='w-full px-[8px] text-[14px] py-[4px] flex text-nowrap' to={'/theory'}>تئوری موسیقی</Link>
                                            </li> */}

                                            <li className="hover:bg-gray-100 cursor-pointer" onClick={() => setDropdownPosition(null)} >
                                                <Link className='w-full px-[8px] text-[14px] py-[4px] flex text-nowrap' to={'/references'}>منابع</Link>
                                            </li>
                                        </ul>
                                    </div>,
                                    document.body
                            )}

                            <div className='relative flex justify-center items-center w-full h-[2.65rem]'>
                                <input
                                    id='input-search-main'
                                    onChange={(e) => setsearchQ(e.target.value)}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                    autoComplete="off"
                                    className='h-full text-[14px] bg-[#F7F7F7] border border-[#E0E0E0] w-full rounded-[0.5rem] pr-[3.5rem] pl-[0.5rem] py-[0.5rem]'
                                    type='text'
                                    placeholder='دنبال چه می گردید؟ '
                                />

                                <svg className='absolute right-[1rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5C18 12.205 17.4311 13.7771 16.4727 15.0368L20.842 19.2829L19.4481 20.7171L15.0613 16.454C13.7975 17.4237 12.216 18 10.5 18ZM16 10.5C16 13.5376 13.5376 16 10.5 16C7.46243 16 5 13.5376 5 10.5C5 7.46243 7.46243 5 10.5 5C13.5376 5 16 7.46243 16 10.5Z" fill="#424750"/>
                                </svg>

                                {(isFocused && searchQ) && (
                                    <div className='box-more-search-header' onMouseDown={(e) => e.preventDefault()}>
                                        <button onClick={(e) => handelSearchLink(`/pieces?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem] border-b border-[#EEEEEE]'>
                                            نمایش “{searchQ}” در قطعات
                                        </button>

                                        <button onClick={(e) => handelSearchLink(`/theories?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem]'>
                                            نمایش “{searchQ}” در تئوری موسیقی نیریز
                                        </button>
                                        
                                        <button onClick={(e) => handelSearchLink(`/articles?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem]'>
                                            نمایش “{searchQ}” در مقالات
                                        </button>

                                        <button onClick={(e) => handelSearchLink(`/references?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem]'>
                                            نمایش “{searchQ}” در منابع
                                        </button>
                                    </div>
                                )}
                            </div>

                            {(query) && (
                                <button onClick={(e) => clear()} className='btn-click text-nowrap h-[2.5rem] mr-[1rem] cursor-pointer rounded-full !w-max !flex justify-center items-center'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.99967 11.1673L5.91634 15.2507C5.76356 15.4034 5.56912 15.4798 5.33301 15.4798C5.0969 15.4798 4.90245 15.4034 4.74967 15.2507C4.5969 15.0979 4.52051 14.9034 4.52051 14.6673C4.52051 14.4312 4.5969 14.2368 4.74967 14.084L8.83301 10.0007L4.74967 5.91732C4.5969 5.76454 4.52051 5.5701 4.52051 5.33398C4.52051 5.09787 4.5969 4.90343 4.74967 4.75065C4.90245 4.59787 5.0969 4.52148 5.33301 4.52148C5.56912 4.52148 5.76356 4.59787 5.91634 4.75065L9.99967 8.83398L14.083 4.75065C14.2358 4.59787 14.4302 4.52148 14.6663 4.52148C14.9025 4.52148 15.0969 4.59787 15.2497 4.75065C15.4025 4.90343 15.4788 5.09787 15.4788 5.33398C15.4788 5.5701 15.4025 5.76454 15.2497 5.91732L11.1663 10.0007L15.2497 14.084C15.4025 14.2368 15.4788 14.4312 15.4788 14.6673C15.4788 14.9034 15.4025 15.0979 15.2497 15.2507C15.0969 15.4034 14.9025 15.4798 14.6663 15.4798C14.4302 15.4798 14.2358 15.4034 14.083 15.2507L9.99967 11.1673Z" fill="#212121"/>
                                    </svg>

                                    <p className='text-[12px] text-[#212121] font-[600] mr-[0.3rem]'> حذف فیلتر</p>
                                </button>
                            )}
                        </div>

                        <div className="relative lg:hidden w-min ml-[1rem]">
                            <button
                                ref={(el) => (buttonRefs.current[0] = el)} 
                                onClick={() => toggleDropdown(0)}
                                className="w-full rounded-[8px] text-nowrap px-[16px] py-[0.25rem] border-[1px] text-black border-black flex justify-between items-center"
                            >
                                <p>تئوری موسیقی</p>

                                <svg className='mr-[0.2rem]' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.00002 9.96746C7.91113 9.96746 7.8278 9.95346 7.75002 9.92546C7.67224 9.89791 7.60002 9.8508 7.53335 9.78413L4.45002 6.7008C4.3278 6.57858 4.26958 6.42569 4.27535 6.24213C4.28069 6.05902 4.34447 5.90635 4.46669 5.78413C4.58891 5.66191 4.74447 5.6008 4.93335 5.6008C5.12224 5.6008 5.2778 5.66191 5.40002 5.78413L8.00002 8.38413L10.6167 5.76746C10.7389 5.64524 10.8918 5.5868 11.0754 5.59213C11.2585 5.59791 11.4111 5.66191 11.5334 5.78413C11.6556 5.90635 11.7167 6.06191 11.7167 6.2508C11.7167 6.43969 11.6556 6.59524 11.5334 6.71746L8.46669 9.78413C8.40002 9.8508 8.3278 9.89791 8.25002 9.92546C8.17224 9.95346 8.08891 9.96746 8.00002 9.96746Z" fill="#212121"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </section>

                <section className='px-[1rem] lg:px-[4rem] my-[2rem] flex justify-between items-start flex-col-reverse lg:flex-row'>
                    <div className='w-full lg:min-w-[16rem] lg:w-[16rem] overflow-y-auto lg:sticky scrooler-costom' id='sidbar-theory'>
                        {seasontable && Array.isArray(seasontable) ? (
                            seasontable?.map((dataS,index) => (
                                <div key={index} className='border lg:border-none rounded-[8px] border-[#D9D9D9] my-[0.5rem]'>
                                    <button className='w-full flex justify-between items-center py-[0.5rem] px-[0.75rem] font-bold cursor-pointer' onClick={(e) => chengeSeasen(dataS.id)}>
                                        <p>{dataS.subtitle}</p>

                                        <svg className={`ml-[0.5rem] lg:ml-0 transition-[0.5s] ${(seasen == dataS.id) ? 'rotate-180' : ''}`} width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.19922 0.60039L5.71638 5.11755C5.87259 5.27376 6.12585 5.27376 6.28206 5.11755L10.7992 0.60039" stroke="black" stroke-width="1.2" stroke-linecap="round"/>
                                        </svg>
                                    </button>

                                    <div className={`text-[#212121] text-[14px] mt-[0.5rem] ${(seasen == dataS.id) ? '' : 'hidden'}`}>
                                        {lessontable.lessontable?.map((dataL,index) => 
                                            (dataL.CHAPTER == seasen) && (
                                                <button key={index} onClick={(e) => chengeLesson(dataL.ID,dataS.id)} className={`transition-[0.5s] w-full text-right px-[0.75rem] py-[0.2rem] ${(dataL.ID == lesson && dataL.CHAPTER == seasen) ? 'lg:bg-green lg:text-white rounded-r-full' : ''}`}>
                                                    <p className='pr-[0.5rem] number-farsi icon-list'>{dataL.TXT}</p>
                                                </button>
                                            )
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <></>
                        )}
                    </div>

                    <div className='hidden lg:block pr-[2rem] py-[0.5rem] border-r border-[#DDDDDD]'>
                        {booklet.booklet && Array.isArray(booklet.booklet) ? (
                            booklet?.booklet?.map((dataB, index) => {
                                if (dataB.CHAPTER == chapter && dataB.SUB_CHAPTER == lesson) {
                                    const imageSrc = `/images/section/p${chapter}_${lesson}_${dataB?.SECTION}.webp`;
                                    let audioSrc;
                                    if(dataB.isAudio == 1)
                                    {
                                        audioSrc = `audio/ketabche/a${chapter}_${lesson}_${dataB?.SECTION}.mp3`;
                                    }

                                    return (
                                        <div key={index} id={`lesson-${dataB.ID}`} className='w-full flex justify-center items-center border-b border-[#DDDDDD] py-[0.5rem]'>
                                            <div className='min-w-[40%] w-[40%] ml-[1rem]'>
                                                <img className='w-full' src={imageSrc} />

                                                {(dataB.isAudio == 1) && (
                                                    <div className="w-full mt-4">
                                                        {audioSrc && (
                                                            <audio key={audioSrc} className="player" controls>
                                                                <source src={audioSrc} type="audio/mpeg" />
                                                            </audio>
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            <div className='w-[60%] text-justify'>
                                                <p>{dataB.TXT}</p>
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })
                        ) : (
                            <></>
                        )}
                    </div>
                </section>

                <div className={`fixed z-[50000] right-0 bottom-0 w-[100vw] h-[100dvh] flex lg:hidden items-end lg:items-center justify-center transition-opacity duration-300 ${isModalOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`} onClick={handleOverlayClick}>
                    <div className={`bg-[#121212] relative z-[50010] rounded-[1rem] transform transition-all ${isModalOpen ? 'scale-100' : 'scale-95'}`}>
                        <div id='modal-container' className="bg-white h-auto max-h-[85vh] overflow-y-auto lg:h-auto w-[100vw] lg:w-[30rem] relative rounded-t-[1rem] lg:rounded-[1rem]">
                            <div className="modal-header">
                                <div className='flex justify-between items-center'>
                                    <p className='text-[#0C0C0C] text-[16px] font-[600]'>
                                        {lessontable?.lessontable?.find(item => item.ID === lessonMobile)?.TXT}
                                    </p>
                                    <button onClick={closeModal}>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.3642 7.54996L16.9499 6.13574L12.0002 11.0855L7.05044 6.13574L5.63623 7.54996L10.586 12.4997L5.63623 17.4495L7.05044 18.8637L12.0002 13.9139L16.9499 18.8637L18.3642 17.4495L13.4144 12.4997L18.3642 7.54996Z" fill="#9E9E9E" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <div className='p-[1rem]'>
                                {booklet.booklet && Array.isArray(booklet.booklet) ? (
                                    booklet?.booklet?.map((dataB, index) => {
                                        if (dataB.CHAPTER == chapter && dataB.SUB_CHAPTER == lesson) {
                                            const imageSrc = `/images/section/p${dataB?.CHAPTER}_${dataB?.SUB_CHAPTER}_${dataB?.SECTION}.webp`;

                                            let audioSrc;
                                            if(dataB.isAudio == 1)
                                            {
                                                audioSrc = `audio/ketabche/a${chapter}_${lesson}_${dataB?.SECTION}.mp3`;
                                            }

                                            return (
                                                <div key={index} id={`modal-lesson-${dataB.ID}`} className='w-full flex justify-center items-center flex-col border-b border-[#DDDDDD] py-[0.5rem]'>
                                                    <div className='w-full'>
                                                        <img className='w-full' src={imageSrc} />
                                                    </div>

                                                    {(dataB.isAudio == 1) && (
                                                        <div className="w-full mt-4">
                                                            {audioSrc && (
                                                                <audio key={audioSrc} className="player" controls>
                                                                    <source src={audioSrc} type="audio/mpeg" />
                                                                </audio>
                                                            )}
                                                        </div>
                                                    )}

                                                    <div className='w-full text-justify'>
                                                        <p>{dataB.TXT}</p>
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>

                    <div onClick={closeModal} className={`fixed z-[50000] right-0 top-0 w-[100vw] h-[100vh]  bg-black opacity-[0.6] bg-opacity-75 transition-opacity duration-300 ${isModalOpen ? 'opacity-100' : 'opacity-0'}`} aria-hidden="true"></div>
                </div>
            </main>
        </>
    );
};

export default Theory;