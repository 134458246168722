import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

const Articles = ({data}) => {
    return (
        <>
            <section className='mb-[2rem]'>
                <div className='px-[2rem] lg:px-[4rem] mb-[1rem]'>
                    <h4 className='text-[28px] font-[700] text-black'>مقالات مرتبط</h4>
                </div>

                <div>
                    <Swiper
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Navigation]} 
                        className="slider-shop pb-[0.5rem]"
                        navigation={true}
                        slidesPerView={'auto'}
                        grabCursor={true}
                        spaceBetween={16}
                        slidesOffsetAfter={32}
                        slidesOffsetBefore={32}
                        breakpoints={{
                            1024: {
                            slidesOffsetAfter :64,
                            slidesOffsetBefore : 64,
                            },
                        }}
                        speed={500}
                    >
                        {data && Array.isArray(data) ? (
                            data.map((data,index) => (
                                <SwiperSlide className="!w-[20rem]">
                                    <div className='w-full border border-[#E1E1E1] rounded-[0.5rem]'>
                                        <Link to={`/articles/${data?.slug}`} className='w-full'>
                                            <div className='w-full'>
                                                <img className='w-full rounded-t-[0.5rem]' src={data?.media?.original} />
                                            </div>

                                            <div className='text-[#212121] whitespace-nowrap text-nowrap p-[1rem] overflow-hidden'>
                                                <p>{data?.title}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                            ))
                        ) : (
                            <p></p>
                        )}
                    </Swiper>
                </div>
            </section>
        </>
    );
};

export default Articles;